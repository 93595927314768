.nav-gap{
    height: 60px;
}
.hero{
    height: calc(100svh - 60px);
    .swiper {
        width: 100%;
        height: 100%;
        .swiper-pagination-bullet{
          background: var(--white);
          height:10px;
          width:10px;

        }
      }
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        display: flex;
        justify-content: center;
        background-repeat: no-repeat !important;
        background-position: center;
        background-size: cover !important;
        align-items: center;

        &.slide1, &.slide2, &.slide3 {
          width: 100%;
          height: 100%;

          // position: relative;
          &.sliden{
            background: url("../Img//home/We-don\'t-create.webp");
            background-position: center center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;

          }
          &.sliden1{
            background: url("../Img//home/Don\'t-j.webp");
            background-position: center center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
          }
          &.sliden2{
            background: url("../Img/home/The-Taste-of-Achievement-Awaits.webp");
            background-position: center center !important;
            background-size: cover !important;
            background-repeat: no-repeat !important;
          }

          .overlay {
            width: 100%;
            height: 100%;
            background: rgba(10, 10, 10, 0.486);
            position: absolute;
            z-index: 1;
          }
          .slide-under-container {
            margin: auto;
            position: absolute;
            display: flex;
            align-items: flex-end;
            padding-bottom: 50px;
            height: 100%;
            z-index: 11;
            .heading {
              width: 100%;

              padding: 20px;
              color:white;
              text-align:center;
              font-size:40px;
            }
          }
        }
      }
}

@media only screen and (max-width: 1000px) {





}