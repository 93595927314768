.cookies>* {
    padding: 5px 5px;
  
}
.cookies 
{
    padding: 10px 15px;
    overflow: hidden;
    max-width: var(--wl);
    // background-color: red!important;
    // max-width: calc(var(--wl) - 40px);
    margin: 100px auto 10px auto;

    h1{
        font-size: 28px!important;
        font-weight: 800;
    }
    h2{
        font-size: 24px!important;
    }
    p{
        font-size: 16px!important;
    }

    .cookies_ul{
        line-height: 25px;
        padding: 0px 23px;
    }

}

// @media only screen and (max-width: 600px) {
//     .cookies {
//         margin: 10px;
//         overflow: hidden;
//         background-color: red!important;
//         h1 h2 p{
//             text-align: center;
//         }
        
//     }
// }