.secIndicator{
    display: flex;
    height: 30px;
    align-items: center;
    gap: calc( var(--gap) /4);
 

    .dash{
        width: 40px;
        height: 3px;
        border-radius: 100px;
        background: var(--accent);
    }
    span{
        color: var(--accent);
        font-weight: 500;
        font-size: 14px;
    }

}



@media only screen and (max-width: 1000px) {


    .secIndicator{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        gap: 0px;
        margin-bottom: 10px;
    
        .dash{
            width: 100%;
            margin: 0px;
        }
    
    }


}