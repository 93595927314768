.pagenot {
    // height: 800px;
    margin: 100px 0px;

    .pagenot-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 30px;

        .oops {
            width: 100%;
            height: 250px;
            background: url("../assets//404.jpg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

@media only screen and (max-width: 600px) {
    .oops {
        width: 100%;
        height: 150px !important;
        background: url("../assets/404.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
    }
}