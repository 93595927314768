.gallery-parent {
    height: auto;
    padding: 50px 0px;
    background-color: var(--grey);

    .gallery-container {

        .election-section {
            margin-top: 60px;
            // padding: 20px;
            width: 100%;

            .gallery-span {
                font-size: 22px;
                font-weight: 500;
            }

            .gallery-line {
                margin: 20px 0px;
                height: 1px;
                width: 100%;
                border: 1px solid rgba(199, 197, 197, 0.521);
            }

            .s-container {

                display: flex;
                justify-content: center;
                align-items: center;

                .swiper {
                    // padding: 50px;
                    height: 100%;
                    width: 100%;
           
                   
                }

                .swiper-slide {
                    border-radius: 10px;
          

                    &.swiper-img11 {
                        background-image: url('../Img/gallery/s11.jpeg');
                    }

                    &.swiper-img12 {
                        background-image: url('../Img/gallery/s17.jpeg');
                    }

                    &.swiper-img13 {
                        background-image: url('../Img/gallery/s16.jpg');
                    }

                    &.swiper-img14 {
                        background-image: url('../Img/gallery/s14.jpg');
                    }

                    &.swiper-img15 {
                        background-image: url('../Img/gallery/s18.jpeg');
                    }
                }

                .swiper {
                    width: 100%;
                    height: 400px;
                    // margin: 20px auto;
                    .swiper-pagination-bullet{
                        background:var(--accent);
                        border: 1px solid var(--white);
                        width:20px;
                        height:20px;
                      }
                }
            }

        }


        //marketing section

        .marketing-section {
            background-color: var(--white);

            .gallery-span {
                font-size: 22px;
                font-weight: 500;
            }

            .gallery-line {
                margin: 20px 0px;
                height: 1px;
                width: 100%;
                border: 1px solid rgba(199, 197, 197, 0.521);
            }

            // .swiper {
            //     padding: 50px;
            // }

            .swiper-slide {
                border-radius: 10px;
                margin-bottom:30px;


                &.swiper-img21 {
                    background-image: url('../Img/marketing/s21.jpeg');
                }

                &.swiper-img22 {
                    background-image: url('../Img/marketing/s22.jpg');
                }

                &.swiper-img23 {
                    background-image: url('../Img/marketing/s23.jpg');
                }

                &.swiper-img24 {
                    background-image: url('../Img/marketing/s24.jpg');
                }

                &.swiper-img25 {
                    background-image: url('../Img/marketing/s25.jpeg');
                }
            }

            .swiper {
                width: 100%;
                height: 400px;
                // margin: 20px auto;
                .swiper-pagination-bullet{
                    background:var(--accent);
                    border: 1px solid var(--white);
                    width:20px;
                    height:20px;
                  }
            }
        }


        //calender section

        .calender-section {
            background-color: var(--grey);

            .gallery-span {
                font-size: 22px;
                font-weight: 500;
            }

            .gallery-line {
                margin: 20px 0px;
                height: 1px;
                width: 100%;
                border: 1px solid rgba(199, 197, 197, 0.521);
            }

            // .swiper {
            //     padding: 50px;
            // }

            .swiper-slide {
                border-radius: 10px;
                margin-bottom:30px;

                &.swiper-img31 {
                    background-image: url('../Img/calender/s31.jpg');
                }

                &.swiper-img32 {
                    background-image: url('../Img/calender/s32.jpg');
                }

                &.swiper-img33 {
                    background-image: url('../Img/calender/s33.jpg');
                }

                &.swiper-img34 {
                    background-image: url('../Img/calender/s34.jpg');
                }

                &.swiper-img35 {
                    background-image: url('../Img/calender/s35.jpg');
                }
            }

            .swiper {
                width: 100%;
                height: 400px;
                margin-bottom:30px;
                // margin: 20px auto;
                .swiper-pagination-bullet{
                    background:var(--accent);
                    border: 1px solid var(--white);
                    width:20px;
                    height:20px;
                  }
            }
        }


    }
}


@media only screen and (max-width:1000px){
    .swiper-button-prev:after,
  .swiper-button-next:after {
    display:block !important;
    background:var(--accent);
    padding:12px;
    color:var(--white);
    font-size:15px !important;;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:100%;
  }
  }