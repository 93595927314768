:root {
  --black: #1a1a1a;
  --white: #ffffff;
  --accent: #FF6600;
  --text: rgb(107, 107, 107);
  --gap: 20px;
  --grey:#f6f6f6;
  --max-width: 1200px;
  --trans: all 0.4s ease-in-out;
  --shadow: 0px 0px 20px rgba(0,0,0,0.2);
  --rad:10px;
}

*::before,
*::after,
* {
  box-sizing: border-box;
  // border: 1px solid red;
  scroll-behavior: smooth !important;
  margin: 0px;
  font-family: 'Poppins', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// font-family: 'Poppins', sans-serif;

body{
  width: 100%;
  overflow-x: hidden;
  
}
html{
  
}


.heading,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

p {
  text-align: justify ;
  font-size: 1rem;
  line-height: 28px;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-weight: 300;
  color: var(--text);
}


@media only screen and (max-width: 1000px) {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6{
    text-align: center;
  }

  



}

.bg-img-cover {
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-radius: 10px!important;
}
.bg-img-contain {
  background-position: center center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border-radius: 10px!important;
}

.parent {
  width: 100vw;

  .cont {
    max-width: var(--max-width);
    margin: auto;
    padding: 0px 10px;
    height: 100%;
    // background: rgba(102, 51, 153, 0.073);
  }
}

.btn {
  cursor: pointer;
  border: 3px solid var(--accent);
  background: var(--accent);
  color: var(--white) !important;
  font-size: 18px;
  padding: 5px 20px;
  border-radius: 200px;
  text-decoration: none;
  transition: var(--trans);
  width:fit-content;

  &:hover {
    background: transparent;
    color: var(--accent) !important;
  }
}

a{
  text-decoration: none;
}


@media only screen and (max-width:1000px){
  .swiper-button-prev:after,
.swiper-button-next:after {
  display:block !important;
  background:var(--accent);
  padding:12px;
  color:var(--white);
  font-size:15px !important;;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius:100%;
}
}

.contain-img{
  .img-box{
  background-size: contain !important;
  // background-color: red!important;
}
}