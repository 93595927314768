.dropdown-mob{
    display: flex;
    flex-direction: column;
    align-items: center;
   background: rgb(238, 237, 237);
   margin-top: -30px !important;
   margin-bottom: -30px !important;
   width: 100%;
   padding: 10px 10px;
   border-radius: 10px;

   a{
    font-size: 10px !important;
    padding-bottom: 10px;
   }



}